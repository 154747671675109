<template>
	<div class="box">
		<div class="text-center">
			<b-button-group>
				<b-button variant="secondary" @click="setDuration(26)">{{ $t('croissance.six_mois') }}</b-button>
				<b-button variant="secondary" @click="setDuration(52)">{{ $t('croissance.un_an') }}</b-button>
				<b-button variant="secondary" @click="setDuration(104)">{{ $t('croissance.deux_ans') }}</b-button>
				<b-button variant="secondary" @click="setDuration(260)">{{ $t('croissance.cinq_ans') }}</b-button>
				<b-button variant="secondary" @click="setDuration(1040)">{{ $t('croissance.vingt_ans') }}</b-button>
			</b-button-group>
		</div>
		<template v-if="ready">
			<h4>{{ $t('croissance.courbe_taille') }}</h4>
			<apexchart type="area" height="350" :options="chartOptions1" :series="series_garrot"></apexchart>
			<h4>{{ $t('croissance.courbe_poids') }}</h4>
			<apexchart type="area" height="350" :options="chartOptions2" :series="series_poids"></apexchart>
		</template>
		<LoadingSpinner v-else />
	</div>
</template>

<script type="text/javascript">
	import VueApexCharts from 'vue-apexcharts'
	import Croissance from "@/mixins/Croissance.js"


	export default {
		name: 'HorseCroissanceGraph',
		mixins: [Croissance],
		props: ['horse_id'],
		data () {
			return {
				series_garrot: [],
				series_poids: [],
				chartOptions1: {
					chart: {
						height: 350,
						type: 'line'
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						curve: 'straight'
					},
					xaxis: {
						type: 'string',
						categories: [],
						convertedCatToNumeric: false,
						labels: {
							rotate: 0,
							hideOverlappingLabels: true,
							style: {
								colors: '#ACB2BC'
							}
						}
					},
					yaxis: {
						labels: {
							style: {
								colors: '#ACB2BC'
							}
						},
						title: {
							text: this.$t("horse.taille"),
							style: {
							color: "#ACB2BC"
							}
						}
					},
					legend: {
						labels: {
							colors: '#ACB2BC'
						}
					},
					colors: ['#2E93fA', '#66DA26', '#546E7A', '#FF8B00', '#FFDB58']
				},
				chartOptions2: {
					chart: {
						height: 350,
						type: 'line'
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						curve: 'straight'
					},
					xaxis: {
						type: 'string',
						categories: [],
						convertedCatToNumeric: false,
						labels: {
							rotate: 0,
							hideOverlappingLabels: true,
							style: {
								colors: '#ACB2BC'
							}
						}
					},
					yaxis: [
						{
							seriesName: this.getTrad('croissance.poulains'),
							show: false,
							labels: {
								style: {
									colors: '#ACB2BC'
								}
							},
							title: {
								text: this.$t("horse.poids"),
								style: {
								color: "#ACB2BC"
								}
							},
							fixed: {
								enabled: true,
							},
						},
						{
							seriesName: this.getTrad('croissance.meme_race'),
							show: false,
							labels: {
								style: {
									colors: '#ACB2BC'
								}
							},
							title: {
								text: this.$t("horse.poids"),
								style: {
								color: "#ACB2BC"
								}
							},
							fixed: {
								enabled: true,
							},
						},
						{
							seriesName: this.getTrad('croissance.meme_pere'),
							show: false,
							labels: {
								style: {
									colors: '#ACB2BC'
								}
							},
							title: {
								text: this.$t("horse.poids"),
								style: {
								color: "#ACB2BC"
								}
							}, fixed: {
								enabled: true,
							},
						},
						{
							seriesName: this.getTrad('croissance.meme_mere'),
							show: false,
							labels: {
								style: {
									colors: '#ACB2BC'
								}
							},
							title: {
								text: this.$t("horse.poids"),
								style: {
								color: "#ACB2BC"
								}
							},
							fixed: {
								enabled: true,
							},
						},
						{
							seriesName: this.getTrad('croissance.mes_poulains'),
							show: false,
							labels: {
								style: {
									colors: '#ACB2BC'
								}
							},
							title: {
								text: this.$t("horse.poids"),
								style: {
								color: "#ACB2BC"
								}
							},
							fixed: {
								enabled: true,
							},
						},
						{
							seriesName: this.getTrad('croissance.horse_nec'),
							opposite: true,
							labels: {
								style: {
									colors: '#DC1258'
								}
							},
							title: {
								text: "N.E.C",
								style: {
								color: "#DC1258"
								}
							},
							fixed: {
								enabled: true,
							},
						},
					],
					legend: {
						labels: {
							colors: '#ACB2BC'
						}
					},
					colors: ['#2E93fA', '#66DA26', '#546E7A', '#FF8B00', '#FFDB58', '#DC1258']
				},
				duration: 26,
				ready: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.ready = false
				this.series_garrot = []
				this.series_poids = []

                await this.$sync.force()
                const id = this.$sync.replaceWithReplicated('horse', this.horse_id)
				const data = await this.getCroissanceComparaison(id, this.duration)

				let horse_garrot = [], horse_poids = [], weeks = []
				let lhg = null, phg = null

				if(!data.avg_race && !data.horse_avg && !data.avg_pere && !data.avg_mere && !data.avg_own && !data.horse_nec){

					let max_horse = data.horse ? Math.max(...data.horse.map(d => d.croissance_week)) : 0

					for(let i = 1; i <= this.duration; i++) {
						weeks.push('S'+i)

						const horse_week = data.horse.find(cr => cr.croissance_week == i)
						if(horse_week) {
							if(horse_week.croissance_garrot) {
								lhg = horse_week.croissance_garrot
							}
							if(horse_week.croissance_poids) {
								phg = horse_week.croissance_poids
							}
						}
						if(i > max_horse) {
							lhg = null
							phg = null
						}
						horse_garrot.push({x: "S"+i, y: lhg})
						horse_poids.push({x: "S"+i, y: phg})
					}

					this.series_garrot = [{
						type: 'line',
						name: this.getTrad('croissance.cheval'),
						data: horse_garrot
					}]
					this.series_poids = [{
						type: 'line',
						name: this.getTrad('croissance.cheval'),
						data: horse_poids
					}]

					this.chartOptions1.colors = ['#2E93fA']
					this.chartOptions2.colors = ['#2E93fA']

				}else{
					let race_garrot = [], pere_garrot = [], mere_garrot = [], own_garrot = [], race_poids = [], pere_poids = [], mere_poids = [], own_poids = [], horse_nec = []

					let lrg = null, lpg = null, lmg= null, log = null, prg = null, ppg = null, pmg= null, pog = null, nec= null
					let max_value = 0, max_prg, max_ppg, max_pmg, max_pog, max_phg

					let max_horse = data.horse ? Math.max(...data.horse.map(d => d.croissance_week)) : 0
					let max_race = data.avg_race ? Math.max(...data.avg_race.map(d => d.croissance_week)) : 0
					let max_pere = data.avg_pere ? Math.max(...data.avg_pere.map(d => d.croissance_week)) : 0
					let max_mere = data.avg_mere ? Math.max(...data.avg_mere.map(d => d.croissance_week)) : 0
					let max_own = data.avg_own ? Math.max(...data.avg_own.map(d => d.croissance_week)) : 0
					let max_nec = data.horse_nec ? Math.max(...data.horse_nec.map(d => d.croissance_week)) : 0

					for(let i = 1; i <= this.duration; i++) {
						weeks.push('S'+i)

						const horse_week = data.horse.find(cr => cr.croissance_week == i)
						if(horse_week) {
							if(horse_week.croissance_garrot) {
								lhg = horse_week.croissance_garrot
							}
							if(horse_week.croissance_poids) {
								phg = horse_week.croissance_poids
								max_phg = Math.max(max_phg, phg)
							}
						}
						if(i > max_horse) {
							lhg = null
							phg = null
						}
						horse_garrot.push({x: "S"+i, y: lhg})
						horse_poids.push({x: "S"+i, y: phg})

						const race_week = data.avg_race.find(cr => cr.croissance_week == i)
						if(race_week) {
							if(race_week.garrot) {
								lrg = race_week.garrot
							}
							if(race_week.poids) {
								prg = race_week.poids
								max_prg = Math.max(max_prg, prg)
							}
						}
						if(i > max_race) {
							lrg = null
							prg = null
						}
						race_garrot.push({x: "S"+i, y: lrg})
						race_poids.push({x: "S"+i, y: prg})

						const pere_week = data.avg_pere.find(cr => cr.croissance_week == i)
						if(pere_week) {
							if(pere_week.garrot) {
								lpg = pere_week.garrot
							}
							if(pere_week.poids) {
								ppg = pere_week.poids
								max_ppg = Math.max(max_ppg, ppg)
							}
						}
						if(i > max_pere) {
							lpg = null
							ppg = null
						}
						pere_garrot.push({x: "S"+i, y: lpg})
						pere_poids.push({x: "S"+i, y: ppg})

						const mere_week = data.avg_mere.find(cr => cr.croissance_week == i)
						if(mere_week) {
							if(mere_week.garrot) {
								lmg = mere_week.garrot
							}
							if(mere_week.poids) {
								pmg = mere_week.poids
								max_pmg = Math.max(max_pmg, pmg)
							}
						}
						if(i > max_mere) {
							lmg = null
							pmg = null
						}
						mere_garrot.push({x: "S"+i, y: lmg})
						mere_poids.push({x: "S"+i, y: pmg})					

						const own_week = data.avg_own.find(cr => cr.croissance_week == i)
						if(own_week) {
							if(own_week.garrot) {
								log = own_week.garrot
							}
							if(own_week.poids) {
								pog = own_week.poids
								max_pog = Math.max(max_pog, pog)
							}
						}
						if(i > max_own) {
							log = null
							pog = null
						}
						own_garrot.push({x: "S"+i, y: log})
						own_poids.push({x: "S"+i, y: pog})

						const nec_week = data.horse_nec.find(nec => nec.croissance_week == i)
						if(nec_week && nec_week.croissance_nec) {
							nec = nec_week.croissance_nec
						}
						if(i > max_nec) {
							nec = null
						}
						horse_nec.push({x: "S"+i, y: nec})
					}
					max_value = Math.max(max_phg, max_prg, max_ppg, max_pmg, max_pog)
					max_phg == max_value ? this.chartOptions2.yaxis[0].show = true : prg == max_value ?  this.chartOptions2.yaxis[1].show = true : ppg == max_value ?  this.chartOptions2.yaxis[2].show = true : pmg == max_value ?  this.chartOptions2.yaxis[3].show = true : this.chartOptions2.yaxis[4].show = true 

					this.series_garrot=[
						{
							type: 'line',
							name: this.getTrad('croissance.poulain'),
							data: horse_garrot
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_race'),
							data: race_garrot
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_pere'),
							data: pere_garrot
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_mere'),
							data: mere_garrot
						},
						{
							type: 'line',
							name: this.getTrad('croissance.mes_poulains'),
							data: own_garrot
						}
					]

					this.series_poids=[
						{
							type: 'line',
							name: this.getTrad('croissance.poulain'),
							data: horse_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_race'),
							data: race_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_pere'),
							data: pere_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_mere'),
							data: mere_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.mes_poulains'),
							data: own_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.horse_nec'),
							data: horse_nec
						}
					]

					this.chartOptions1.colors = ['#2E93fA', '#66DA26', '#546E7A', '#FF8B00', '#FFDB58']
					this.chartOptions2.colors = ['#2E93fA', '#66DA26', '#546E7A', '#FF8B00', '#FFDB58', '#DC1258']
				}

				this.chartOptions1.xaxis.categories = weeks
				this.chartOptions2.xaxis.categories = weeks
				this.ready = true
			},

			setDuration(mounth) {
				this.duration = mounth
				this.init_component()
			}
		},

		components: {
			apexchart: VueApexCharts,
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') 
		}
	}

</script>